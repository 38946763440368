<template>
    <div v-if="false" class="row">
        <div class="card">
            <div class="card-body">
                <h5 class="table-heading">{{$t('devices.title')}}</h5>

                <div class="alert alert-custom alert-indicator-left indicator-info" role="alert">
                    <div class="alert-content">
                        <span class="alert-title">Information!</span>
                        <span class="alert-text">If you have received a device from us please follow the instructions below to link it to your site!</span>
                    </div>
                </div>

                <p><strong>Step 1.</strong> Please make sure the device is powered on. For our VARS tablets you can find the power button on the right-hand side near the top.</p>
                <p><strong>Step 2.</strong> Please allow a couple of minutes for the device to power up.</p>
                <p><strong>Step 3.</strong> Once the device has powered up, swipe up on the screen to unlock. </p>
                <p><strong>Step 4.</strong> Once the device is unlocked, please tap the <strong>WiFi Centre</strong> icon at the top of the screen.<img src="images/wificentre.jpg" width="50px" style="margin-left: 20px;" height="50px" alt="WiFi Centre"></p>
                <p><strong>Step 5.</strong> Please select your WiFi network from the list on the screen and enter your password in the input box that shows up.</p>
                <img src="images/wificentrescreen.jpg" style="height: 250px; width: auto;" v-viewer>
                <p class="m-t-xs"><strong>Step 6.</strong> Once you have connected to your WiFi network, please tap the <strong>back</strong> button at the top left of the screen.</p>
                <img src="images/wificentrepassword.jpg" style="height: 250px; width: auto;" v-viewer>
                <p class="m-t-xs"><strong>Step 7.</strong> Please tap the <strong>VARS</strong> icon at the top left of the screen to launch the VARS app.</p>
                <p><strong>Step 8.</strong> Once the VARS app has launched, you will be greeted with a staging code which should be 4 characters long. Please enter that code into the input box shown below these instructions and press the <strong>Register</strong> button.</p>
                <p><strong>Congratulations!</strong> Your new device is ready to use.</p>
                <img src="images/varshome.jpg" style="height: 250px; width: auto;" v-viewer>

            </div>
        </div>
    </div>

    <div class="row">

        <div class="card">
            <div class="card-body">
                <div class="form-group">
                    <label for="stagingCode">Staging Code</label>
                    <input type="text" class="form-control m-t-xxs" v-model="stagingKey" id="stagingCode" placeholder="Enter staging code">
                </div>

                <button :disabled="loading" class="btn btn-sm btn-success m-t-sm" @click="stage">
                    <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                    <span>Register Device</span>
                </button>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import authHeader from '../../services/auth-header';

export default {
    name: 'RegisterDevice',
    data(){
        return {
            stagingKey: null,
            errorMessage: null,
            loading: false
        }
    },
    mounted(){

    },
    methods: {
        stage(){
            this.loading = true;
            axios.post(`https://api.varsanpr.com/api/staging/${this.stagingKey}`, {}, {
                headers: authHeader()
            })
            .then(response => {
                this.$success("Device registered successfully!");
                this.$router.push({ name: 'DashboardClaims' });
            })
            .catch(error => {
                this.errorMessage = error.response.data;
                this.$error(this.errorMessage);
            })
            .finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>

<style scoped>
.table-heading {
    padding-top: 0;
    margin-bottom: 20px;
}
</style>